// https://github.com/gatsbyjs/gatsby/issues/8078

import React, { useContext, useRef, useState, useEffect } from "react"
import Swiper from "react-id-swiper"
import { Link } from "gatsby"
import { useTranslation } from 'react-i18next';

import SEO from "components/seo"
import VideoWrapper from "components/VideoWrapper"
import Heading from "components/Heading"
import Quote from "components/Quote"
import ContentSlotOffset from "components/ContentSlotOffset"
import HeroImageFull from "components/HeroImageFull"
import { Photo } from "components/Img"
import Interweave from "interweave"
import imgQuoteImage from "images/imgHomepage--quote-image-item.jpg"
import imgAwardSymbol from "images/award--symbol.png"
import { useMappingRoutes } from 'utils/useMappingRoutes';
import { LanguageContext } from "i18n/context";

const ViewHomepage = ({pageData}) => {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext)
  const mappedRoutes = useMappingRoutes(language);

  const refActiveBox = useRef()
  const refServiceContainer = useRef()
  const {
    servicesBlock,
    locationBlock,
    awardsBlock,
    commitmentBlock,
    peopleBlock,
    testimonial,
    page,
    aboutUs,
    videoHomepage,
    awards,
    services,
    heroText,
  } = pageData
  const [swiper, updateSwiper] = useState(null)
  const [currentSlideIndex, updateSlideIndex] = useState(
    Math.floor(services.length / 2)
  )

  const params = {
    slidesPerView: 1,
    spaceBetween: 15,
    centeredSlides: true,
    loop: false,
    speed: 600,
  }

  const paramsServicesSwiper = {
    containerClass: "swiper-container services-swiper",
    slidesPerView: 1,
    spaceBetween: 15,
    loop: false,
    centeredSlides: true,
    initialSlide: currentSlideIndex,
    slideActiveClass: "swiper-slide-active is-active",
    width: 320,
  }

  const onClickItem = clickedIndex => {
    if (!swiper) return

    swiper.slideTo(clickedIndex)
  }

  useEffect(() => {
    if (!swiper) return

    swiper.on("slideChange", function () {
      updateSlideIndex(this.activeIndex)
    })
  }, [swiper])

  useEffect(() => {
    if (refActiveBox.current) {
      const height = refActiveBox.current.offsetHeight
      const offset = 120
      refServiceContainer.current.style.paddingBottom = `${height + offset}px`
    }
  }, [currentSlideIndex]);

  return (
    <>
      <SEO title={page} description={aboutUs.text} />
      <div className="max-container real-hero wrapper--hp--hero">
        <HeroImageFull imageName="homepageHeroImage">
          <div className="hp-hero-text">
            <Interweave content={heroText} />
          </div>
        </HeroImageFull>
      </div>

      <div className="max-container wrapper--hp-testimonial">
        <div className="container max-1048">
          <div className="hp-testimonials-container">
            <div className="testimonial-swiper">
              <div className="col-images testimonial-images">
                <Swiper {...params}>
                  <div className="each-image">
                    <img src={imgQuoteImage} alt="Quote" />
                  </div>
                </Swiper>
              </div>
              <div className="col-quote">
                <Quote>
                  <Interweave content={testimonial} />
                </Quote>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-container wrapper--hp-video">
        <Heading className="align-center">{videoHomepage.title}</Heading>
        <VideoWrapper videoId={videoHomepage.videoId} />
      </div>

      <div className="max-container wrapper--hp-about-us">
        <div className="container max-1048">
          <ContentSlotOffset
            className="content-slot--about"
            offsetRight={true}
            image={"homepageAbout"}
            heading={aboutUs.title}
          >
            <p>{aboutUs.text}</p>
            <Link to={mappedRoutes.about} className="btn--about btn--outline">
              {t('global.btnSeemore')}
            </Link>
          </ContentSlotOffset>
        </div>
      </div>
      <div className="max-container wrapper--hp-people">
        <div className="container max-1048">
          <ContentSlotOffset
            className="content-slot--people"
            offsetLeft={true}
            image={"homepagePeople"}
            heading={peopleBlock.title}
          >
            <p>{peopleBlock.text}</p>
            <Link to={mappedRoutes.people} className="btn--about btn--outline">
              {t('global.btnSeemore')}
            </Link>
          </ContentSlotOffset>
        </div>
      </div>

      <div
        ref={refServiceContainer}
        className="max-container wrapper--hp-services"
      >
        <div className="container max-1048">
          <Heading>{servicesBlock.title}</Heading>
          <div className="hp-service-list">
            <Swiper {...paramsServicesSwiper} getSwiper={updateSwiper}>
              {services.map(({ name, list, content }, index) => {
                const hasInfoPanel = Boolean(
                  swiper && swiper.activeIndex === index
                )

                return (
                  <div
                    key={`service-${index}`}
                    className="service-column"
                    onClick={() => onClickItem(index)}
                    onKeyPress={() => onClickItem(index)}
                    role="button"
                    tabIndex={"0"}
                  >
                    <div className="service--name">{name}</div>
                    <div className="service--see-more">
                      <button className="btn-service--see-more btn btn--outline style-1">
                        {t('global.btnSeemore')}
                      </button>
                    </div>
                    {hasInfoPanel && (
                      <div ref={refActiveBox} className="service--info">
                        <ul>
                          {content &&
                            content.map((line, indexLine) => (
                              <li key={`each-line-${indexLine}`}>{line}</li>
                            ))}
                          {list &&
                            list.map((line, indexLine) => (
                              <li key={`each-line-list-${indexLine}`}>
                                - {line}
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )
              })}
            </Swiper>
          </div>
        </div>
      </div>

      <div className="max-container wrapper--hp-commitment">
        <Heading>{commitmentBlock.title}</Heading>
        <Quote>
          <Interweave
            content={commitmentBlock.content}
          />
        </Quote>
      </div>

      <div className="max-container wrapper--hp-awards">
        <div className="container max-1048">
          <Heading>{awardsBlock.title}</Heading>
          <div className="awards-list">
            {awards.map(({ logo, title }, index) => {
              return (
                <div
                  key={`homepage-award-${index}`}
                  className={`award-item award-item-${index}`}
                >
                  <div className="award-symbol">
                    <img src={imgAwardSymbol} alt={title} />
                  </div>
                  <div className="award-title">
                    <span>{title}</span>
                  </div>
                  <div className="award-logo">
                    <Photo
                      imgStyle={{
                        objectFit: "contain",
                      }}
                      isFixed={true}
                      data={logo}
                      alt={title}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="max-container wrapper--hp-location">
        <div className="container max-1048">
          <Heading>
            <Interweave noWrap content={locationBlock.title} />
          </Heading>
          <div className="hp-locations">
            <div className="col-location fx-1">
              <Photo
                className="locale-column"
                data={locationBlock.aus.image}
                alt={locationBlock.aus.name}
              />
              <h3 className="locale-name">{locationBlock.aus.name}</h3>
            </div>
            <div className="col-location fx-1">
              <Photo
                className="locale-column"
                data={locationBlock.london.image}
                alt={locationBlock.london.name}
              />
              <h3 className="locale-name">{locationBlock.london.name}</h3>
            </div>
            <div className="col-location fx-1">
              <Photo
                className="locale-column"
                data={locationBlock.newyork.image}
                alt={locationBlock.newyork.name}
              />
              <h3 className="locale-name">{locationBlock.newyork.name}</h3>
            </div>
            <div className="col-location fx-1">
              <Photo
                className="locale-column"
                data={locationBlock.paris.image}
                alt={locationBlock.paris.name}
              />
              <h3 className="locale-name">{locationBlock.paris.name}</h3>
            </div>
            <div className="col-location fx-1">
              <Photo
                className="locale-column"
                data={locationBlock.hcm.image}
                alt={locationBlock.hcm.name}
              />
              <h3 className="locale-name">{locationBlock.hcm.name}</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewHomepage
