import React from 'react'
import cx from 'classnames';
import Heading from "components/Heading";
import Img from "components/Img"

const ContentSlotOffset = ({className, heading, offsetLeft, offsetRight, image, children}) => {
  return (
    <div className={cx(`content-slot--root ${className}`, {
      'offset-right': offsetRight,
      'offset-left': offsetLeft,
    })}>
      <div className="background--layer"></div>
      <div className="image-abs">
        <Img
          name={image}
          isFluid={true}
          alt={heading}
        />
      </div>
      {heading && <Heading>{heading}</Heading>}
      <div className="content">
        {children}
      </div>
    </div>
  )
}

ContentSlotOffset.defaultProps = {
  className: '',
  heading: '',
  offsetLeft: false,
  offsetRight: false,
}

export default ContentSlotOffset